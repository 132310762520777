import { useState } from 'react';

import { ReactComponent as AddSmall } from 'assets/icons/systemicons/add_small.svg';
import { Button } from 'components/buttons';
import { useCreateMemberDialog } from 'components/createNewV3/CreateNewDialog';
import Scrollbar from 'components/scrollbar';
import Popover from 'components/shared/popover';
import useCheckUserRight from 'hooks/useCheckUserRight';
import { FeedItem } from 'types/graphqlTypes';

import { AddDialog, Assets, Content, EmptyState, Header } from './components';

import { Article, ButtonWrapper, Sections } from './styled';

const SEND_TO_LABEL = 'Add to existing...';
const CREATE_LABEL = 'Create new...';

export interface ExtendedFeedItem extends FeedItem {
  accessToken?: string;
  mPublishedAt: string;
  useProxy?: boolean;
}

interface FeedViewerProps {
  feedItem: ExtendedFeedItem;
  searchWords?: string[];
  closeTickerDialog?: () => void;
  noBorderRadius?: boolean;
}

function FeedViewer({
  feedItem,
  searchWords,
  closeTickerDialog = () => {},
  noBorderRadius = false,
}: Readonly<FeedViewerProps>) {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [, showCreateMemberDialog] = useCreateMemberDialog();
  const [checkUserRight] = useCheckUserRight();
  const canCreateStory = checkUserRight('story', 'create');
  const canCreatePitch = checkUserRight('pitch', 'create');
  const hasCreationPermission = canCreateStory || canCreatePitch;

  if (!feedItem) return <EmptyState />;

  const handleClosePopover = () => {
    setAnchorEl(null);
  };

  const onSendToStory = (e: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(e.currentTarget);
  };

  const {
    assets,
    mId,
    provider,
    mDescription,
    href,
    byline,
    mPublishedAt,
    mediatopics,
    versioncreated,
    firstcreated,
    mContent,
    mTitle,
    accessToken,
    useProxy,
  } = feedItem;
  const onCreateStory = (e: React.MouseEvent<HTMLButtonElement>) => {
    showCreateMemberDialog({
      mId: mId,
      mTitle: mTitle,
      provider: provider,
      anchorEl: e.currentTarget,
      useFeedMutation: true,
      onClose: () => closeTickerDialog(),
    });
  };

  const hasAssets = assets && assets.length > 0;

  return (
    <Article $noBorderRadius={noBorderRadius}>
      <Scrollbar valueChanged={mId} dark>
        <Sections>
          <Header
            source={byline ?? provider}
            title={mTitle ?? mDescription}
            href={href}
            mPublishedAt={mPublishedAt}
            mediatopics={mediatopics}
            searchWords={searchWords}
            firstcreated={firstcreated}
            versioncreated={versioncreated}
          />
          <Content text={mDescription} searchWords={searchWords} />
          {hasAssets && (
            <Assets feedItem={feedItem} accessToken={accessToken} useProxy={useProxy} />
          )}
          <Content text={mContent} searchWords={searchWords} />
        </Sections>
      </Scrollbar>
      <ButtonWrapper>
        <Button
          ariaLabel="Add feed to story"
          variant="outlined"
          usage="outlined"
          dark
          onClick={onSendToStory}
          startIcon={<AddSmall />}
        >
          {SEND_TO_LABEL}
        </Button>
        <Button
          ariaLabel="Create story from feed"
          onClick={onCreateStory}
          startIcon={<AddSmall />}
          usage="cta"
          variant="contained"
          disabled={!hasCreationPermission}
        >
          {CREATE_LABEL}
        </Button>
      </ButtonWrapper>
      {anchorEl && (
        <Popover
          anchorEl={anchorEl}
          transformOrigin={{ vertical: 'bottom', horizontal: 'left' }}
          open={Boolean(anchorEl)}
          onClose={handleClosePopover}
        >
          <AddDialog
            provider={provider}
            feedId={mId}
            close={handleClosePopover}
            closeTickerDialog={closeTickerDialog}
          />
        </Popover>
      )}
    </Article>
  );
}

export default FeedViewer;
