import { useCallback, useEffect, useMemo, useState } from 'react';
import { BasePoint, BaseRange, Editor, Path, Range } from 'slate';
import { ReactEditor, useSlate } from 'slate-react';

import preventDefaultAndStopPropagation from 'utils/preventDefaultAndStopPropagation';

const { isCollapsed, edges } = Range;

const toPixel = (num: number) => `${num}px`;

export interface Position<T> {
  top: T;
  left: T;
  bottom: T;
}

const initialPosition: Position<string | null> = {
  top: null,
  left: null,
  bottom: null,
};

interface TextRegExp {
  beforeExp: RegExp;
  afterExp: RegExp;
}
const getBeforeMatch = (editor: Editor, start: BasePoint, beforeExp: RegExp) => {
  const wordBefore = Editor.before(editor, start, { unit: 'word' });

  let beforeRange = wordBefore && Editor.range(editor, wordBefore, start);
  let beforeText = beforeRange && Editor.string(editor, beforeRange);
  let beforeMatch = beforeText?.match(beforeExp);

  if (!beforeMatch) {
    const beforePoint = wordBefore && Editor.before(editor, wordBefore);
    const inferredPoint =
      beforePoint && Path.equals(beforePoint.path, start.path) ? beforePoint : start;
    beforeRange = beforePoint && Editor.range(editor, inferredPoint, start);
    beforeText = beforeRange && Editor.string(editor, beforeRange);
    beforeMatch = beforeText?.match(beforeExp);
  }
  return [beforeMatch, beforeRange] as const;
};

const getAfterMatch = (editor: Editor, start: BasePoint, afterExp: RegExp) => {
  const afterPoint = Editor.after(editor, start);
  const afterRange = Editor.range(editor, start, afterPoint);
  const afterText = Editor.string(editor, afterRange);
  return afterText.match(afterExp);
};

/**
 * A custom hook that provides autocomplete functionality for slate editor.
 *
 * @param suggestions - An array of suggestions for autocompletion.
 * @param onEnter - A callback function triggered when the user confirms a suggestion.
 * @param setSearchValue - A function to update the search value based on user input.
 * @param matcher - An object containing regular expressions used for text matching.
 * @param target - target node where the popper will show
 * @param setTarget - A function to update target
 * @param switchDOMRange - boolean to determine which DOM range to use for portal position
 */

const useCustomCombobox = <T>(
  suggestions: T[],
  onEnter: (suggestion: T, target: BaseRange | null) => void,
  setSearchValue: (val: string) => void,
  { beforeExp, afterExp }: TextRegExp,
  target: BaseRange | null,
  setTarget: (target: BaseRange | null) => void,
  switchDOMRange = false,
) => {
  const editor = useSlate();
  const { selection } = editor;
  const suggestionCount = useMemo(() => (suggestions ?? []).length, [suggestions]);

  const [position, setPosition] = useState<Position<string | null>>(initialPosition);
  const [cursor, setCursor] = useState(0);

  useEffect(() => {
    if (selection && isCollapsed(selection)) {
      const [start] = edges(selection);

      const [beforeMatch, beforeRange] = getBeforeMatch(editor, start, beforeExp);
      const afterMatch = getAfterMatch(editor, start, afterExp);

      if (beforeMatch && afterMatch) {
        if (beforeRange) setTarget(beforeRange);
        const valueToSet = beforeMatch.input && !beforeMatch.index ? beforeMatch.input : '/';
        setSearchValue(valueToSet);
        setCursor(0);
        return;
      }
      setTarget(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selection, setTarget, setSearchValue]);

  useEffect(() => {
    if (target && suggestionCount > 0) {
      const domRange = switchDOMRange
        ? window?.getSelection()?.getRangeAt(0)
        : ReactEditor.toDOMRange(editor as ReactEditor, target);

      if (!domRange) return;

      const rect = domRange.getBoundingClientRect();
      const documentHeight = document.body.getBoundingClientRect().height;

      const isAboveBisectHeight = rect.y > documentHeight / 2;

      setPosition({
        bottom: isAboveBisectHeight ? toPixel(documentHeight - rect.bottom + 24) : null,
        left: toPixel(rect.left),
        top: isAboveBisectHeight ? null : toPixel(rect.top + 24),
      });
    }

    return () => {
      setPosition(initialPosition);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [suggestionCount, target, editor]);

  const onArrowDown = useCallback(
    (event: KeyboardEvent) => {
      preventDefaultAndStopPropagation(event);
      setCursor((previousCursor) =>
        previousCursor + 1 >= suggestionCount ? 0 : previousCursor + 1,
      );
    },
    [suggestionCount],
  );

  const onArrowUp = useCallback(
    (event: KeyboardEvent) => {
      preventDefaultAndStopPropagation(event);
      setCursor((previousCursor) =>
        previousCursor <= 0 ? suggestionCount - 1 : previousCursor - 1,
      );
    },
    [suggestionCount],
  );

  const handleEnter = useCallback(() => {
    onEnter(suggestions[cursor], target);
  }, [suggestions, cursor, target]);

  const onKeyDown = useCallback(
    (event: KeyboardEvent) => {
      switch (event.key) {
        case 'ArrowDown':
          onArrowDown(event);
          break;

        case 'ArrowUp':
          onArrowUp(event);
          break;

        case 'Enter':
          preventDefaultAndStopPropagation(event);
          handleEnter();
          setTarget(null);
          break;

        case 'Escape':
          setTarget(null);
          break;
        default:
          break;
      }
    },
    [onArrowDown, onArrowUp, handleEnter],
  );

  const showCombobox = target && suggestionCount > 0;

  useEffect(() => {
    if (showCombobox) window.addEventListener('keydown', onKeyDown, true);

    return () => {
      window.removeEventListener('keydown', onKeyDown, true);
    };
  }, [onKeyDown, showCombobox]);

  return { cursor, position };
};

export default useCustomCombobox;
