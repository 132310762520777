import { useCallback, useState } from 'react';
import styled from '@emotion/styled/macro';
import { distanceInWordsToNow, format, isToday, isYesterday } from 'date-fns';

import { ReactComponent as More } from 'assets/icons/systemicons/more_vertical.svg';
import Avatar from 'components/avatar/Avatar';
import { IconButton } from 'components/buttons';
import { DeleteDialog } from 'components/dialogs/CommonDialogs';
import Popover from 'components/dialogs/PopoverBuilder';
import Editor from 'components/editor';
import variants from 'components/editor/constants/types/editorVariants';
import ListItem from 'components/listItem';
import Text from 'components/text/Text';
import useGetUser from 'hooks/useGetUser';
import { Box, HStack, VStack } from 'layouts/box/Box';
import { EditorValue } from 'types/editor';
import { User } from 'types/members';
import { Conversation } from 'types/messageHub';

import useCreateComments from './hooks/useCreateComments';
import CommentInput from './CommentInput';

function formatDate(date: string) {
  if (isToday(date)) {
    return format(date, 'h:mm A');
  } else if (isYesterday(date)) {
    return format(date, 'h:mm A') + ' Yesterday';
  } else {
    return format(date, 'MMM d, YYYY h:mm a');
  }
}

const StyledVStack = styled(VStack)`
  &:hover {
    background: ${({ theme }) => theme.palette.dina.statusOnHover};
  }
  svg:hover path {
    fill: white;
  }
`;

const StyledListItem = styled(ListItem)`
  width: 100%;
`;

const TitleWrapper = styled('div')`
  cursor: pointer;
  :hover {
    text-decoration: underline;
  }
`;

function CommentDetails({
  comment,
  currentUserId,
  openUserDetails,
}: {
  comment: Conversation;
  currentUserId: string;
  openUserDetails: (user?: User) => void;
}) {
  const { getUser } = useGetUser();
  const [createComment] = useCreateComments();

  const [editing, setEditing] = useState(false);
  const [openDeleteConfirm, setOpenDeleteConfirm] = useState(false);
  const [loading, setLoading] = useState(false);

  const createdBy = getUser(comment?.mCreatedById ?? '');

  const updateComment = useCallback(
    async (newComment?: EditorValue) => {
      setLoading(true);
      if (newComment) {
        await createComment({
          ...comment,
          mContent: JSON.stringify(newComment),
          crudAction: 'UPDATE',
        });
      }
      setLoading(false);
      setEditing(false);
    },
    [comment, createComment],
  );

  const deleteComment = useCallback(async () => {
    setLoading(true);
    await createComment({
      ...comment,
      crudAction: 'DELETE',
    });
    setLoading(false);
    setOpenDeleteConfirm(false);
  }, [comment, createComment]);

  return (
    <StyledVStack width="100%" padding="4px 8px 0px 16px" flex="1 0 auto">
      <HStack width="100%" gap="8px">
        <Avatar title={createdBy?.mTitle} imageKey={createdBy?.mAvatarKey} size={28} />
        <VStack flex="1" alignItems="flex-start" gap="2px">
          <TitleWrapper onClick={() => openUserDetails(createdBy)}>
            <Text variant="listItemLabel" color="highEmphasis">
              {createdBy?.mTitle}
            </Text>
          </TitleWrapper>
          <Text variant="caption" color="watermark">
            {formatDate(comment?.mCreatedAt ?? '')}{' '}
            {comment?.mCreatedAt !== comment.mUpdatedAt
              ? `(edited ${distanceInWordsToNow(comment?.mUpdatedAt ?? '', { addSuffix: true })})`
              : ''}
          </Text>
        </VStack>
        {currentUserId === createdBy?.mId && (
          <Popover>
            <Popover.Trigger asChild>
              <span className="moreButton">
                <IconButton usage="text" size={24} iconSize={16}>
                  <More />
                </IconButton>
              </span>
            </Popover.Trigger>
            <Popover.Content style={{ minWidth: 220, overflow: 'hidden' }}>
              <VStack width="100%" alignItems="flex-start" padding="0px">
                <StyledListItem
                  text="Edit"
                  info="Edit comment"
                  onClick={() => setEditing(!editing)}
                  icon={undefined}
                />
                <StyledListItem
                  text="Delete"
                  info="Delete comment"
                  onClick={() => setOpenDeleteConfirm(true)}
                  icon={undefined}
                />
                <DeleteDialog
                  open={openDeleteConfirm}
                  onClose={() => setOpenDeleteConfirm(false)}
                  onClick={deleteComment}
                  title="Delete Comment?"
                  message={`Are you sure you want to delete this comment?
                    This action is permanent and cannot be undone.`}
                  confirmLabel="Delete"
                  loading={loading}
                  overlayStyle={{ zIndex: 1400 }}
                />
              </VStack>
            </Popover.Content>
          </Popover>
        )}
      </HStack>
      {editing ? (
        <Box width="100%" margin="8px 0 0">
          <CommentInput
            onSave={updateComment}
            initialValue={JSON.parse(comment?.mContent ?? '{}') as EditorValue}
            isEditing
          />
        </Box>
      ) : (
        <Editor
          showHoveringTooltip={false}
          background="transparent"
          renderToolbar={() => null}
          height="100%"
          readOnly={true}
          padding={0}
          value={JSON.parse(comment?.mContent || '{}') as EditorValue}
          placeholder=""
          variant={variants.MESSAGE}
        />
      )}
    </StyledVStack>
  );
}

export default CommentDetails;
