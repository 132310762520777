import React from 'react';
import { css, Theme } from '@emotion/react';
import styled from '@emotion/styled';
import { ErrorOutline } from '@material-ui/icons';

import infoImage from 'assets/icons/systemicons/info_off.svg';
import { ReactComponent as Left } from 'assets/icons/systemicons/speechbubble_left_pointer.svg';
import { ReactComponent as Right } from 'assets/icons/systemicons/speechbubble_right_pointer.svg';
import Avatar from 'components/avatar/Avatar';
import Editor from 'components/editor';
import variants from 'components/editor/constants/types/editorVariants';
import accessibleOnClick from 'utils/accessibleOnClick';
import useLogger from 'utils/useLogger';

import parseContent from './utils';

const commonPointer = (theme: Theme) => css`
  position: absolute;
  bottom: 0;
  & path:nth-of-type(2) {
    fill: ${theme.palette.dina.buttonBorderOutlined};
    fill-opacity: 1;
  }
`;

const LeftPointer = styled(Left)<{ isErrorMessage?: boolean }>`
  ${({ theme }) => commonPointer(theme)};
  left: 0;
  transform: translate3d(30px, 0, 0);
  & path:nth-of-type(1) {
    fill: ${({ theme, isErrorMessage }) => {
      if (isErrorMessage) {
        return theme.palette.dina.errorBackground;
      }
      return theme.palette.dina.backgroundResting;
    }};
    fill-opacity: 1;
  }
`;

const RightPointer = styled(Right)<{ isErrorMessage?: boolean }>`
  ${({ theme }) => commonPointer(theme)};
  right: 0;
  transform: translate3d(6px, 0, 0);
  & path:nth-of-type(1) {
    fill: ${({ theme, isErrorMessage }) => {
      if (isErrorMessage) {
        return theme.palette.dina.errorBackground;
      }
      return 'rgba(148, 138, 248, 0.15)';
    }};
    fill-opacity: 1;
  }
`;
const IconMessage = styled('div')`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  padding: 0.5rem;
  & svg {
    margin-right: 0.5rem;
  }
`;

const Container = styled('div')<{ isSelfMessage: boolean }>`
  position: relative;
  width: 100%;
  margin-block: 8px 2px;
  display: flex;
  align-items: flex-end;
  justify-content: ${({ isSelfMessage }) => (isSelfMessage ? 'flex-end' : 'flex-start')};
`;

const MessageContainer = styled('div')<{ isSelfMessage: boolean; isErrorMessage?: boolean }>`
  ${({ theme }) => theme.typography.dina.body2};
  max-width: 100%;
  min-width: 28px;
  margin-left: 8px;
  padding-left: 4px;
  border: 1px solid ${({ theme }) => theme.palette.dina.buttonBorderOutlined};
  border-radius: 8px;
  box-sizing: border-box;
  clip-path: ${({ isSelfMessage }) =>
    isSelfMessage
      ? `polygon(0 0, 100% 0, 100% calc(100% - 14px), 
          calc(100% - 8px) calc(100% - 14px), calc(100% - 8px) 100%, 0 100%)`
      : `polygon(0px 0px, 100% 0px, 100% 100%, 8px 100%, 
          8px calc(100% - 14px), 0px calc(100% - 14px));`};
  background-color: ${({ isSelfMessage, isErrorMessage, theme }) => {
    if (isErrorMessage) {
      return theme.palette.dina.errorBackground;
    }
    if (isSelfMessage) {
      return 'rgba(148, 138, 248, 0.15)';
    }
    return theme.palette.dina.backgroundResting;
  }};
`;

const OpenItemWrapper = ({
  children,
  canOpen,
  handleOpen = () => null,
}: {
  children: React.ReactElement;
  canOpen?: boolean;
  handleOpen?: () => void;
}) => {
  return canOpen ? (
    <div style={{ cursor: 'pointer ' }} {...accessibleOnClick(handleOpen, 'presentation')}>
      {children}
    </div>
  ) : (
    children
  );
};

interface MessageRowProps extends Omit<React.ComponentProps<typeof OpenItemWrapper>, 'children'> {
  mContent?: string | null;
  mAvatarKey?: string;
  mTitle: string;
  isSelfMessage: boolean;
  showEditButton: boolean;
}

function MessageRow({
  mContent,
  mAvatarKey,
  mTitle,
  isSelfMessage,
  showEditButton,
  canOpen,
  handleOpen,
}: Readonly<MessageRowProps>) {
  const logger = useLogger('MessageRow');

  const { parsedContent, parsedError } = parseContent(logger, mContent);

  return (
    <Container isSelfMessage={isSelfMessage}>
      {!isSelfMessage && (
        <Avatar
          tooltipContent={mTitle}
          variant="user"
          size={28}
          key={mAvatarKey}
          src={!mAvatarKey ? infoImage : undefined}
          title={mTitle}
        />
      )}
      {isSelfMessage ? (
        <RightPointer isErrorMessage={Boolean(parsedError)} />
      ) : (
        <LeftPointer isErrorMessage={Boolean(parsedError)} />
      )}

      <MessageContainer isSelfMessage={isSelfMessage} isErrorMessage={!!parsedError}>
        {parsedError ? (
          <IconMessage>
            <ErrorOutline />
            {parsedError}
          </IconMessage>
        ) : (
          <OpenItemWrapper canOpen={canOpen} handleOpen={handleOpen}>
            <Editor
              showHoveringTooltip={false}
              background="transparent"
              renderToolbar={() => null}
              height="100%"
              readOnly={showEditButton}
              padding={0}
              value={parsedContent}
              placeholder=""
              variant={variants.MESSAGE}
            />
          </OpenItemWrapper>
        )}
      </MessageContainer>
    </Container>
  );
}

export default MessageRow;
