import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { Chip } from '@material-ui/core';
import { capitalize } from 'lodash';

import Text from 'components/text';
import { HStack, VStack } from 'layouts/box/Box';
import { topLevelMediaTopicsMap } from 'utils/constants/mediaTopics';

const TitleSection = styled('div')`
  border-radius: 8px 8px 0 0;
  min-height: 100px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding: 12px 16px 16px 16px;
`;

const SectionChip = styled(Chip)`
  margin-top: 4px;
  margin-bottom: 8px;
  min-width: 40px;
  align-self: flex-start;
  background-color: ${({ theme }) => theme.palette.dina.chipBackground};
  .MuiChip-label {
    ${({ theme }) => css`
      ${theme.typography.dina.overline};
      color: ${theme.palette.dina.blackMediumEmphasis};
    `}
  }
`;

const Metadata = styled('div')`
  ${({ theme }) => css`
    border-bottom: 1px solid ${theme.palette.dina.dividerLight};
    border-top: 1px solid ${theme.palette.dina.dividerLight};
  `};
  min-height: 48px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 24px 0 16px;
  padding: 13px 0;
  flex: wrap;
`;

interface HeaderProps {
  href?: string;
  mPublishedAt: string;
  mediatopics?: string[];
  source?: string;
  searchWords?: string[];
  title?: string;
  firstcreated?: string;
  versioncreated?: string;
}

const getSourceUrl = (href?: string) => {
  if (href && href.length > 0) {
    return (
      <Text
        as="a"
        variant="caption"
        color="blackMediumEmphasis"
        href={href}
        target="_blank"
        rel="noopener noreferrer"
      >
        open original article
      </Text>
    );
  }
  return '';
};

export default function FeedViewerHeader({
  href,
  mPublishedAt,
  mediatopics,
  source,
  title,
  firstcreated,
  versioncreated,
}: Readonly<HeaderProps>) {
  const sourceUrl = getSourceUrl(href);

  const topics =
    mediatopics
      ?.map((topic) => capitalize(topLevelMediaTopicsMap[topic]?.name) ?? undefined)
      .filter(Boolean) ?? [];

  return (
    <>
      <TitleSection>
        <HStack justifyContent="flex-start" gap="2px">
          {topics.map((topic) => (
            <SectionChip key={topic} label={topic} />
          ))}
        </HStack>
        <Text variant="h4" color="blackHighEmphasis">
          {title}
        </Text>
      </TitleSection>
      <Metadata>
        <span>
          <Text variant="listItemLabel" color="blackHighEmphasis" as="p">
            {`${source} `}
            {sourceUrl}
          </Text>
          <Text variant="caption" color="blackMediumEmphasis">
            {topics.join(', ') ?? <>&nbsp;</>}
          </Text>
        </span>
        <VStack alignItems="flex-end" gap="4px">
          <Text variant="caption" color="blackMediumEmphasis" style={{ textAlign: 'end' }}>
            First created: {new Date(firstcreated ?? mPublishedAt).toLocaleString()}
          </Text>
          <Text variant="caption" color="blackMediumEmphasis" style={{ textAlign: 'end' }}>
            Version created: {new Date(versioncreated ?? mPublishedAt).toLocaleString()}
          </Text>
        </VStack>
      </Metadata>
    </>
  );
}
