import { useRef, useState } from 'react';
import { useContextMenu } from 'react-contexify';
import { FetchResult, useMutation } from '@apollo/client';
import { debounce } from 'lodash';

import { ReactComponent as AddIcon } from 'assets/icons/systemicons/add.svg';
import { ReactComponent as NotesIcon } from 'assets/icons/systemicons/notes.svg';
import LoadingIndicator from 'components/loadingIndicator';
import FileInput from 'components/uploadMediaInput';
import { MenuItemText } from 'lib/Menu/MenuItem';
import CREATE_NOTE from 'operations/mutations/createNote';
import GET_NOTES from 'operations/queries/getNotes';
import { getMemberQuery } from 'operations/queryVariables';
import { useStoryMolecule } from 'screens/storyV2/store/story';
import usePanes from 'screens/storyV2/useStoryPanes';
import { StoryTab, storyTabs, useOrderFormFilter } from 'store';
import { Note } from 'types';
import { MemberTypeEnum } from 'types/graphqlTypes';
import preventDefaultAndPropagation from 'utils/preventDefaultAndStopPropagation';

import { StyledMenuItem, SvgHoverWrapper } from './styled';

function OtherMenuItems({
  closeMenu,
  isStory,
  paneIndex,
}: {
  closeMenu: () => void;
  isStory: boolean;
  paneIndex?: number;
}) {
  const { updateStoryPane } = usePanes();
  const { useStory, useCanAccessNotes } = useStoryMolecule();
  const [story] = useStory();
  const canAccessNotes = useCanAccessNotes();
  const [createNote] = useMutation(CREATE_NOTE);
  const [creatingAsset, setCreatingAsset] = useState<boolean>();
  const [, setFormFilter] = useOrderFormFilter();
  const { show } = useContextMenu({ id: 'orderForms' });

  const fileInputRef = useRef<HTMLInputElement | null>(null);

  const onCompleteTask = (label: StoryTab, id?: string) => {
    setCreatingAsset(false);
    closeMenu();
    updateStoryPane(paneIndex ?? 0, label, id);
  };

  const handleNoteCreate = debounce(() => {
    closeMenu();
    createNote({
      variables: {
        input: {
          mTitle: '',
          mDescription: '',
          mStoryId: story?.mId,
        },
      },

      update: (proxy, mutationResult: Omit<FetchResult<{ createNote: Note }>, 'context'>) => {
        const notesList: { getNotes: Note[] } | null = proxy.readQuery({
          query: GET_NOTES,
          variables: getMemberQuery(story?.mId as string),
        });
        const newNote = mutationResult.data?.createNote;

        const alreadyExists = notesList?.getNotes?.find((note) => note.mRefId === newNote?.mRefId);
        if (alreadyExists) {
          onCompleteTask(storyTabs.notes, newNote?.mRefId);
          return;
        }

        const newNoteList = notesList?.getNotes ? [newNote, ...notesList.getNotes] : [newNote];
        proxy.writeQuery({
          query: GET_NOTES,
          variables: getMemberQuery(story?.mId as string),
          data: {
            getNotes: newNoteList,
          },
        });
        onCompleteTask(storyTabs.notes, newNote?.mRefId);
      },
    }).then(
      () => {},
      () => {},
    );
  }, 300);

  const onAddAsset = () => {
    if (creatingAsset) return;
    fileInputRef?.current?.click();
  };

  const handleCreateTaskForStory = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
    setFormFilter({ types: [MemberTypeEnum.Story] });
    show({
      event,
      props: {
        resourceId: story?.mId,
        resourceType: story?.mType,
        onClose: () => onCompleteTask(storyTabs.tasks),
      },
    });
  };

  return (
    <>
      {canAccessNotes && (
        <StyledMenuItem
          $type="note"
          onClick={handleNoteCreate}
          onDoubleClick={preventDefaultAndPropagation}
          key="note"
        >
          <SvgHoverWrapper>
            <NotesIcon />
          </SvgHoverWrapper>
          <MenuItemText>New Note</MenuItemText>
        </StyledMenuItem>
      )}

      {/** Todo: will be added later */}
      {/* <StyledMenuItem $type='booking' key='booking'>
        <SvgHoverWrapper>
          <BookingIcon />
        </SvgHoverWrapper>
        <MenuItemLabel>New Booking</MenuItemLabel>
      </StyledMenuItem> */}
      <StyledMenuItem
        $type="asset"
        key="asset"
        onClick={onAddAsset}
        onDoubleClick={preventDefaultAndPropagation}
      >
        <SvgHoverWrapper>
          {creatingAsset ? <LoadingIndicator height={16} width={16} /> : <AddIcon />}
        </SvgHoverWrapper>
        <MenuItemText>Add new media</MenuItemText>
        <FileInput
          mId={story?.mId as string}
          inputRef={fileInputRef}
          memberType={story?.mType}
          onStart={() => setCreatingAsset(true)}
          onComplete={() => onCompleteTask(storyTabs.assets)}
        />
      </StyledMenuItem>
      {isStory && (
        <StyledMenuItem $type="task" key="task" onClick={handleCreateTaskForStory}>
          <SvgHoverWrapper>
            <AddIcon />
          </SvgHoverWrapper>
          <MenuItemText>Create task</MenuItemText>
        </StyledMenuItem>
      )}
    </>
  );
}

export default OtherMenuItems;
